
const post = async (event) => {

  event.preventDefault();
  window.location.href = 'thanks.html';

  const name = document.getElementById('name').value;
  const company = document.getElementById('company').value;
  const email = document.getElementById('email').value;
  const message = document.getElementById('message').value;
  const phoneNumber = document.getElementById('phonenumber').value;

  const body = JSON.stringify({
    "text": `問い合わせがありました！\n会社名：${company}\n名前：${name}\nメールアドレス：${email}\n内容：${message}\n電話番号：${phoneNumber}`
  });

  await fetch('https://hooks.slack.com/services/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', {
    method: 'POST',
    body: body
  })
  .then(response => response.json())
  .then(data => {
    console.log('Success:', data);
  })
  .catch(error => {
    console.error('Error:', error);

  });
}

export default post;